import React, { useState, useEffect, useCallback } from "react";
import "./App.css";
import { FaSun, FaMoon } from "react-icons/fa";
import CardForm from "./components/CardForm";
import Spinner from "./components/Spinner";
import ResponseSection from "./components/ResponseSection";
import {
  fetchCardInfoFromMongoDB,
  fetchCardInfoFromExternalApiAndLLM,
  saveCardInfoToMongoDB,
  fetchCardInfoByPerksFromExternalApiAndLLM,
} from "./api/ApiServices.js";

const App = () => {
  const [issuer, setIssuer] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardName, setCardName] = useState("");
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [benefit, setBenefit] = useState("");
  const [searchOption, setSearchOption] = useState(""); // Add searchOption state

  const handleSubmitFindCardByBenefit = useCallback(
    async (perk) => {
      setLoading(true);
      setError("");

      console.log("Benefit search submitted:", perk);

      try {
        const payload = { perk: `${perk.trim()}` };
        const llmResponse = await fetchCardInfoByPerksFromExternalApiAndLLM(
          payload
        );

        if (llmResponse) {
          setResponse({ ...llmResponse, searchOption: "benefit" });

          // Save the data to MongoDB after fetching from the external API
          await saveCardInfoToMongoDB({
            issuer,
            cardType,
            cardName,
            summary: llmResponse.summary,
            summaryOfLinks: llmResponse["summaryOfLinks"],
            countAndCostList: llmResponse["countAndCostList"],
            userInput: llmResponse.userInput,
          });
        }
      } catch (error) {
        console.error("Error during the card info submission:", error);
        setError("An error occurred while processing your request.");
      } finally {
        setLoading(false);
      }
    },
    [issuer, cardType, cardName]
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const searchOption = params.get("searchOption");
    const issuer = params.get("issuer");
    const cardType = params.get("cardType");
    const cardName = params.get("cardName");
    const benefit = params.get("benefit");
    const autoSubmit = params.get("autoSubmit"); // Get autoSubmit parameter

    if (searchOption) setSearchOption(searchOption);
    if (issuer) setIssuer(issuer);
    if (cardType) setCardType(cardType);
    if (cardName) setCardName(cardName);
    if (benefit) setBenefit(benefit);

    if (autoSubmit === "true") {
      // Ensure autoSubmit is a string "true"
      if (searchOption === "card" && (issuer || cardType || cardName)) {
        handleSubmit(issuer, cardType, cardName);
      } else if (searchOption === "benefit" && benefit) {
        handleSubmitFindCardByBenefit(benefit);
      }
    }
  }, [handleSubmitFindCardByBenefit]); // Add handleSubmitFindCardByBenefit to the dependency array

  const handleToggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
    document.body.classList.toggle("dark-mode", !isDarkMode);
  };

  const handleSubmit = async (issuer, cardType, cardName) => {
    setLoading(true);
    setError("");

    try {
      const mongoDBResponse = await fetchCardInfoFromMongoDB(
        issuer,
        cardType,
        cardName
      );

      if (mongoDBResponse && mongoDBResponse.status_code === 200) {
        setResponse({ ...mongoDBResponse, searchOption: "card" });
      } else {
        // MongoDB record not found, fetch from external API

        const payload = {
          card_name: `${issuer.trim()} ${cardType.trim()} ${cardName.trim()}`,
        };
        console.log("Payload:", payload);
        const llmResponse = await fetchCardInfoFromExternalApiAndLLM(payload);

        if (llmResponse) {
          setResponse({ ...llmResponse, searchOption: "card" });

          // Save the data to MongoDB after fetching from the external API
          await saveCardInfoToMongoDB({
            issuer,
            cardType,
            cardName,
            summary: llmResponse.summary,
            summaryOfLinks: llmResponse["summaryOfLinks"],
            countAndCostList: llmResponse["countAndCostList"],
            userInput: llmResponse.userInput,
          });
        }
      }
    } catch (error) {
      console.error("Error during the card info submission:", error);
      setError("An error occurred while processing your request.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`app-container ${isDarkMode ? "dark-mode" : ""}`}>
      <header className="app-header text-center mb-4">
        <h1>Welcome to Perks FOMO</h1>
        <button
          className="btn btn-link theme-toggle"
          onClick={handleToggleDarkMode}
        >
          {isDarkMode ? <FaSun size={24} /> : <FaMoon size={24} />}
        </button>
      </header>
      <main className={`app-main ${isDarkMode ? "dark-mode" : ""}`}>
        <CardForm
          issuer={issuer}
          cardType={cardType}
          cardName={cardName}
          setIssuer={setIssuer}
          setCardType={setCardType}
          setCardName={setCardName}
          handleSubmit={handleSubmit}
          loading={loading}
          benefit={benefit}
          setBenefit={setBenefit}
          handleSubmitFindCardByBenefit={handleSubmitFindCardByBenefit}
          isDarkMode={isDarkMode}
          setResponse={setResponse} // Pass setResponse to CardForm
          searchOption={searchOption} // Pass searchOption to CardForm
          setSearchOption={setSearchOption} // Pass setSearchOption to CardForm
        />
        {loading && <Spinner />}
        {error && <p className="text-danger">{error}</p>}
        {response && (
          <ResponseSection response={response} isDarkMode={isDarkMode} />
        )}
      </main>
    </div>
  );
};

export default App;
