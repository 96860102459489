import React from "react";

const CardForm = ({
  issuer,
  cardType,
  cardName,
  setIssuer,
  setCardType,
  setCardName,
  handleSubmit,
  loading,
  benefit,
  setBenefit,
  handleSubmitFindCardByBenefit,
  isDarkMode,
  setResponse,
  searchOption, // Add searchOption prop
  setSearchOption, // Add setSearchOption prop
}) => {
  const onSubmit = (e) => {
    e.preventDefault();
    setResponse(null); // Hide response section
    handleSubmit(issuer, cardType, cardName);
  };

  const onSubmitFindCardByBenefit = (e) => {
    e.preventDefault();
    setResponse(null); // Hide response section
    handleSubmitFindCardByBenefit(benefit);
  };

  return (
    <section className="container">
      <h2>What are you looking for?</h2>
      <div className="search-options mb-3">
        <div className="form-check">
          <input
            type="radio"
            id="search-card"
            className="form-check-input"
            value="card"
            checked={searchOption === "card"}
            onChange={() => setSearchOption("card")}
          />
          <label className="form-check-label" htmlFor="search-card">
            Search Your Credit Card Benefit
          </label>
        </div>
        <div className="form-check">
          <input
            type="radio"
            id="search-benefit"
            className="form-check-input"
            value="benefit"
            checked={searchOption === "benefit"}
            onChange={() => setSearchOption("benefit")}
          />
          <label className="form-check-label" htmlFor="search-benefit">
            Search for a Credit Card with Specific Benefit
          </label>
        </div>
      </div>

      {searchOption === "card" ? (
        <section
          className={`card-selection-section ${isDarkMode ? "dark-mode" : ""}`}
        >
          <div id="card">
            <h2>Select Your Credit Card</h2>
            <form onSubmit={onSubmit} className="mb-3 card-form-grid">
              <div className="mb-3">
                <label htmlFor="issuer" className="form-label">
                  Card Issuer:
                </label>
                <select
                  id="issuer"
                  value={issuer}
                  onChange={(e) => setIssuer(e.target.value)}
                  className="form-select"
                >
                  <option value="">Select the issuer</option>
                  <option value="alaska usa federal credit union">
                    Alaska USA Federal Credit Union
                  </option>
                  <option value="amazon">Amazon</option>
                  <option value="bank of america">Bank of America</option>
                  <option value="barclays">Barclays</option>
                  <option value="bb&t">BB&T (now Truist)</option>
                  <option value="best buy">Best Buy</option>
                  <option value="capital one">Capital One</option>
                  <option value="capital credit union">
                    Capital Credit Union
                  </option>
                  <option value="chase">Chase Bank</option>
                  <option value="chime">Chime</option>
                  <option value="citi retail services">
                    Citi Retail Services
                  </option>
                  <option value="citibank">Citibank</option>
                  <option value="city furniture">City Furniture</option>
                  <option value="costco">Costco</option>
                  <option value="discover">Discover</option>
                  <option value="financial credit union">
                    Financial Credit Union
                  </option>
                  <option value="first tech federal credit union">
                    First Tech Federal Credit Union
                  </option>
                  <option value="hsbc">HSBC</option>
                  <option value="home depot">Home Depot</option>
                  <option value="jcpenney">JCPenney</option>
                  <option value="kohl's">Kohl's</option>
                  <option value="lowes">Lowe's</option>
                  <option value="macy's">Macy's</option>
                  <option value="merrick bank">Merrick Bank</option>
                  <option value="navy federal credit union">
                    Navy Federal Credit Union
                  </option>
                  <option value="pnc">PNC Bank</option>
                  <option value="petco">Petco</option>
                  <option value="rewards credit union">
                    Rewards Credit Union
                  </option>
                  <option value="sears">Sears</option>
                  <option value="synchrony bank">Synchrony Bank</option>
                  <option value="tj maxx">TJ Maxx</option>
                  <option value="td bank">TD Bank</option>
                  <option value="us bank">U.S. Bank</option>
                  <option value="wells fargo">Wells Fargo</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="card-type" className="form-label">
                  Card Type:
                </label>
                <select
                  id="card-type"
                  value={cardType}
                  onChange={(e) => setCardType(e.target.value)}
                  className="form-select"
                >
                  <option value="">Select card type</option>
                  <option value="visa">Visa</option>
                  <option value="mastercard">MasterCard</option>
                  <option value="discover">Discover</option>
                  <option value="american express">American Express</option>
                  <option value="diners club">Diners Club</option>
                  <option value="jcb">JCB</option>
                  <option value="store credit card">Store Credit Card</option>
                  <option value="debit card">Debit Card</option>
                  <option value="prepaid card">Prepaid Card</option>
                  <option value="secured credit card">
                    Secured Credit Card
                  </option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="card-name" className="form-label">
                  Card Name:
                </label>
                <input
                  type="text"
                  id="card-name"
                  value={cardName}
                  onChange={(e) => setCardName(e.target.value)}
                  className="form-control"
                />
              </div>
              <button type="submit" className="submit-btn" disabled={loading}>
                {loading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </section>
      ) : searchOption === "benefit" ? (
        <section
          className={`card-selection-section ${isDarkMode ? "dark-mode" : ""}`}
        >
          <div id="benefit">
            <h2>Enter the perks you are looking for:</h2>
            <form
              onSubmit={onSubmitFindCardByBenefit}
              className="card-form-grid"
            >
              <div className="mb-3">
                <label htmlFor="benefit" className="form-label">
                  Benefit:
                </label>
                <input
                  type="text"
                  id="benefit"
                  value={benefit}
                  onChange={(e) => setBenefit(e.target.value)}
                  className="form-control"
                />
              </div>
              <button type="submit" className="submit-btn" disabled={loading}>
                {loading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </section>
      ) : (
        <span>
          <br />
          Please select one of the above options
        </span>
      )}
    </section>
  );
};

export default CardForm;
